import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./CourseStartSetSection.module.scss";

interface CourseStartSetSectionProps {
  showIntroductionImage?: boolean;
}

export function CourseStartSetSection({
  showIntroductionImage,
}: CourseStartSetSectionProps): React.ReactNode {
  return (
    <>
      <div className="clear__sp">
        <div className="row">
          <div className="col-12 col-m-8 col-offset-m-2">
            <div className="text__center mg__bottom__l">
              <h2 className="text__xl text__bold text__white">
                友だち紹介限定
                <br />
                継続コーススタートセット
              </h2>
            </div>
            {showIntroductionImage && (
              <Image
                src="https://asset.basefood.co.jp/images/bnr/bnr_invited_0805.png"
                alt=""
                aspectRatio="59 / 12"
                containerClassName={clsx(
                  styles.image,
                  styles.fullImages,
                  "lazyload",
                  "mg__bottom__l"
                )}
              />
            )}
          </div>
          <div className="col-12 col-m-4 col-offset-m-4 mg__bottom__m">
            <Image
              src="https://asset.basefood.co.jp/images/pkg/box_80.png"
              alt="継続コーススタートセット"
              aspectRatio="490 / 293"
              containerClassName={styles.image}
            />
          </div>
        </div>
      </div>
      <div className="clear__pc">
        <div className="container">
          <div className="row">
            <div className="col-12 col-m-8 col-offset-m-2">
              <div className="text__center mg__bottom__l">
                <h2 className="text__xl text__bold text__white">
                  <span className="wsnr">友だち紹介限定</span>
                  <br />
                  <span className="wsnr">継続コーススタートセット</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pd__bottom__m">
              {showIntroductionImage && (
                <Image
                  src="https://asset.basefood.co.jp/images/bnr/bnr_invited_sp_0117.png"
                  alt=""
                  aspectRatio="658 / 435"
                  containerClassName={clsx(
                    styles.image,
                    styles.fullImages,
                    "mg__bottom__m",
                    "lazyload"
                  )}
                />
              )}
              <Image
                src="https://asset.basefood.co.jp/images/pkg/box_80.png"
                alt="継続コーススタートセット"
                aspectRatio="92 / 55"
                containerClassName={clsx(
                  styles.image,
                  styles.fullImages,
                  "mg__bottom__m",
                  "lazyload"
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
