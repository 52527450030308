import clsx from "clsx";

import styles from "./FriendsOnlySpecialSet.module.scss";

export function FriendsOnlySpecialSet(): React.ReactNode {
  return (
    <>
      <div className={clsx("container", styles.bgGreenKv)}>
        <div className={clsx("row", "breadcrumb", "mg__bottom__m", "mg__top__m")}>
          <div className="col-12">
            <p className="text__s">
              <i className={clsx("fas", "fa-angle-right")} />
              <span className="text__red">友だち紹介限定セット</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.bgInvited} />
    </>
  );
}
