import clsx from "clsx";

import styles from "./FeelingChangeBodySection.module.scss";

export function FeelingChangeBodySection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <table className={clsx(styles.scheduleTable, "clear__sp")}>
            <tbody>
              <tr>
                <td />
                <td
                  className={clsx(styles.scheduleTitle, "text__bold", "text__center")}
                  colSpan={2}
                >
                  お支払い時期
                </td>
                <td className={clsx(styles.scheduleSubTitle, "text__bold", "text__center")}>
                  お届け時期
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    styles.scheduleTitle,
                    styles.scheduleFixWidth,
                    "text__center",
                    "text__bold"
                  )}
                  rowSpan={2}
                >
                  初回
                </td>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleTopSolid,
                    styles.schedulePayment,
                    "text__s",
                    "text__bold",
                    "text__center"
                  )}
                >
                  Amazon Pay/
                  <span className="wsnr">クレジット</span>
                </td>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleTopSolid,
                    styles.scheduleLeftDashed,
                    styles.schedulePayment,
                    "text__s",
                    "text__bold",
                    "text__center"
                  )}
                >
                  代金引換
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleTopSolid,
                    styles.scheduleLeftSolid
                  )}
                  rowSpan={2}
                >
                  日付を指定せずにお届けでご注文から2〜3日で出荷
                  <br />
                  <br />
                  日付を指定してお届けでご注文から4〜23日以内を指定可能
                </td>
              </tr>
              <tr>
                <td className={clsx(styles.scheduleContent, styles.scheduleTopSolid)}>
                  ご注文と同時
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleLeftDashed,
                    styles.scheduleTopSolid
                  )}
                >
                  商品受け取り時
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleFixWidth,
                    "text__center",
                    "text__bold"
                  )}
                >
                  2回目以降
                </td>
                <td className={clsx(styles.scheduleContent, styles.scheduleTopSolid)}>
                  お届け日の4日前
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleLeftDashed,
                    styles.scheduleTopSolid
                  )}
                >
                  商品受け取り時
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleTopSolid,
                    styles.scheduleLeftSolid
                  )}
                >
                  初回お届け日より4週間後にお届け
                  <br />
                  <br />
                  お届け日5日前までマイページにてお届け日や内容を変更可能
                </td>
              </tr>
            </tbody>
          </table>
          <table className={clsx(styles.scheduleTable, "clear__pc")}>
            <tbody>
              <tr>
                <td />
                <td
                  className={clsx(styles.scheduleTitle, "text__bold", "text__center")}
                  colSpan={2}
                >
                  お支払い時期
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    styles.scheduleTitle,
                    styles.scheduleFixWidth,
                    "text__center",
                    "text__bold"
                  )}
                  rowSpan={2}
                >
                  初回
                </td>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleTopSolid,
                    styles.schedulePayment,
                    "text__s",
                    "text__bold",
                    "text__center"
                  )}
                >
                  Amazon Pay/
                  <span className="wsnr">クレジット</span>
                </td>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleTopSolid,
                    styles.scheduleLeftDashed,
                    styles.schedulePayment,
                    "text__s",
                    "text__bold",
                    "text__center"
                  )}
                >
                  代金引換
                </td>
              </tr>
              <tr>
                <td className={clsx(styles.scheduleContent, styles.scheduleTopSolid)}>
                  ご注文と同時
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleLeftDashed,
                    styles.scheduleTopSolid
                  )}
                >
                  商品受け取り時
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleFixWidth,
                    "text__center",
                    "text__bold"
                  )}
                >
                  2回目以降
                </td>
                <td className={clsx(styles.scheduleContent, styles.scheduleTopSolid)}>
                  お届け日の4日前
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleLeftDashed,
                    styles.scheduleTopSolid
                  )}
                >
                  商品受け取り時
                </td>
              </tr>
            </tbody>
          </table>
          <table className={clsx(styles.scheduleTable, "clear__pc")}>
            <tbody>
              <tr>
                <td />
                <td className={clsx(styles.scheduleSubTitle, "text__bold", "text__center")}>
                  お届け時期
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    styles.scheduleTitle,
                    styles.scheduleFixWidth,
                    "text__center",
                    "text__bold"
                  )}
                >
                  初回
                </td>
                <td
                  className={clsx(
                    styles.scheduleContent,
                    styles.scheduleTopSolid,
                    styles.scheduleLeftSolid
                  )}
                >
                  日付を指定せずにお届けでご注文から2〜3日で出荷
                  <br />
                  <br />
                  日付を指定してお届けでご注文から4〜23日以内を指定可能
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    styles.scheduleSubTitle,
                    styles.scheduleFixWidth,
                    styles.scheduleTopSolid,
                    "text__center",
                    "text__bold"
                  )}
                >
                  2回目以降
                </td>
                <td className={clsx(styles.scheduleContent, styles.scheduleTopSolid)}>
                  初回お届け日より4週間後にお届け
                  <br />
                  <br />
                  お届け日5日前までマイページにてお届け日や内容を変更可能
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
