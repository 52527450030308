import { FirstSpecialBenefitsSection } from "@/components";
import { ProductsBySkuCalcPriceModel } from "@/models/product/type";

import { CourseStartSetSection } from "../CourseStartSetSection";
import { SetPackListsSection } from "../SetPackListsSection";

interface StartSetSectionProps {
  productsBySkuCalcPrice: ProductsBySkuCalcPriceModel[];
  showIntroductionImage?: boolean;
}

export function StartSetSection({
  productsBySkuCalcPrice,
  showIntroductionImage = false,
}: StartSetSectionProps): React.ReactNode {
  return (
    <div className="container pd__top__xl pd__bottom__xl">
      <CourseStartSetSection showIntroductionImage={showIntroductionImage} />
      <SetPackListsSection productsBySkuCalcPrice={productsBySkuCalcPrice} />
      <FirstSpecialBenefitsSection />
    </div>
  );
}
