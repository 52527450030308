import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./BreakfastFitSection.module.scss";

export function BreakfastFitSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-8 col-offset-m-2">
          <div className="text__center mg__bottom__m">
            <h2 className="text__xl text__bold">
              朝食にぴったりの
              <br />
              完全栄養パン
            </h2>
            <p className="text__l text__bold text__center mg__top__l">栄養バランスとコスト比較</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-m-5 col-offset-m-1">
          <div className="row mg__bottom__s">
            <div className="col-6">
              <Image
                src="https://asset.basefood.co.jp/images/ph/ph_bb_chocolate_v1_12_comparison.png"
                alt=""
                aspectRatio="1 / 1"
                containerClassName={clsx(styles.image, styles.productImage)}
              />
              <p className="text__s text__bold text__center mg__top__s">BASE BREAD チョコレート</p>
            </div>
            <div className="col-6">
              <Image
                src="https://asset.basefood.co.jp/images/ph/ph_bb_breakfast_comparison.png"
                alt=""
                aspectRatio="1 / 1"
                containerClassName={clsx(styles.image, styles.productImage)}
              />
              <p className="text__s text__bold text__center mg__top__s">コンビニで揃えた食事</p>
            </div>
            <div className="col-6">
              <p className="text__l text__bold text__yellow text__center">
                408円
                <sup className="sup text__bold text__black">※1</sup>
              </p>
            </div>
            <div className="col-6">
              <p className="text__l text__bold text__center">
                約650円
                <sup className="sup text__bold text__black">※2</sup>
              </p>
            </div>
          </div>
          <p className="text__s text__gray__dark mg__top__m mg__bottom__l">
            ※1 継続コース初回の場合。2回目以降458円
            <br />
            ※2 サラダ、サンドイッチ、野菜ジュース、ヨーグルトの場合
          </p>
        </div>
        <div className="col-12 col-m-5">
          <div className="row">
            <div className="col-12 col-m-10 col-offset-m-1">
              <Image
                src="https://asset.basefood.co.jp/images/graph/graph_bb_chocolate_v1_12_redar_comparison.png"
                alt=""
                aspectRatio="1 / 1"
                containerClassName={clsx(styles.image, styles.productImage)}
              />
            </div>
          </div>
          <p className="text__s text__gray__dark mg__top__m">
            ※
            栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合。
          </p>
        </div>
      </div>
    </div>
  );
}
