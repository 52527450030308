import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./BodyChangeSection.module.scss";

export function BodyChangeSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <div className="text__center mg__bottom__l">
            <h2 className="text__xl text__bold">
              <span className="wsnr">続けると、</span>
              <span className="wsnr">からだの変化を実感</span>
            </h2>
          </div>
          <p className="text__m text__justify">
            栄養バランスのいい主食をらくに続けるために、お得なBASE
            FOOD継続コースをおすすめしています。
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1 mg__top__l">
          <div className={clsx("bg__white", styles.subscriptionBox)}>
            <div className={styles.subscriptionMerit}>
              <dl className={styles.subscriptionMeritDetail}>
                <dt>
                  <span className="text__xxl text__bold text__yellow">1.</span>
                </dt>
                <dd>
                  <p className="text__l text__bold">継続コースならこんなにお得！</p>
                </dd>
              </dl>
              <div className="clear__sp">
                <table className={clsx(styles.compareTablePc, "mg__top__m")}>
                  <tbody>
                    <tr>
                      <td />
                      <td className="text__s text__bold text__center">価格</td>
                      <td className="text__s text__bold text__center">おまけ</td>
                      <td className="text__s text__bold text__center">特典</td>
                    </tr>
                    <tr>
                      <td className="text__s text__bold text__center">
                        <Image
                          src="https://asset.basefood.co.jp/images/parts/icon-logo.svg"
                          alt="公式ショップ"
                          size={{ width: 66, height: 40 }}
                          aspectRatio="33 / 22"
                          containerClassName={styles.image}
                        />
                        <br />
                        公式ショップ
                      </td>
                      <td className="text__s text__center">
                        <p className="mg__bottom__s">
                          継続コース初回
                          <br />
                          <span className="text__bold">
                            <span className="text__m">204</span>円
                          </span>
                          <sup className="sup">※1</sup>
                        </p>
                        2回目以降
                        <br />
                        <span className="text__bold">
                          <span className="text__m">229</span>円
                        </span>
                        <sup className="sup">※1</sup>
                      </td>
                      <td className="text__s text__center">
                        <p className="text__bold mg__bottom__xs">
                          BASE Cookies
                          <br />
                          ココナッツ1袋
                          <sup className="sup text__normal">※3</sup>
                        </p>
                      </td>
                      <td className="text__s text__center">
                        ランクに応じて<span className="text__bold">アウトレット</span>や
                        <span className="text__bold">限定イベントにご招待</span>、
                        <span className="text__bold">新商品30%割引</span>
                        <sup className="sup">※4</sup>など
                      </td>
                    </tr>
                    <tr>
                      <td className="text__s text__center">
                        <Image
                          src="https://asset.basefood.co.jp/images/parts/icon-shop.svg"
                          alt="コンビニ"
                          size={{ width: 40, height: 40 }}
                          aspectRatio="1 / 1"
                        />
                        <br />
                        コンビニ
                      </td>
                      <td className="text__s text__center">
                        <span className="text__m">281</span>円<sup className="sup">※2</sup>
                      </td>
                      <td className="text__m text__bold text__center">×</td>
                      <td className="text__m text__bold text__center">×</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="clear__pc">
                <table className={clsx(styles.compareTableSP, "mg__top__m")}>
                  <tbody>
                    <tr>
                      <td />
                      <td className="text__s text__bold text__center">
                        <Image
                          src="https://asset.basefood.co.jp/images/parts/icon-logo.svg"
                          alt="公式ショップ"
                          size={{ width: 66, height: 40 }}
                          aspectRatio="33 / 22"
                          containerClassName={styles.image}
                        />
                        <br />
                        公式ショップ
                      </td>
                      <td className="text__s text__center">
                        <Image
                          src="https://asset.basefood.co.jp/images/parts/icon-shop.svg"
                          alt="コンビニ"
                          size={{ width: 40, height: 40 }}
                          aspectRatio="1 / 1"
                        />
                        <br />
                        コンビニ
                      </td>
                    </tr>
                    <tr>
                      <td className="text__s text__bold text__center">価格</td>
                      <td className="text__s text__center">
                        <p className="mg__bottom__s">
                          継続コース初回
                          <br />
                          <span className="text__bold">
                            <span className="text__m">204</span>円
                          </span>
                          <sup className="sup">※1</sup>
                        </p>
                        2回目以降
                        <br />
                        <span className="text__bold">
                          <span className="text__m">229</span>円
                        </span>
                        <sup className="sup">※1</sup>
                      </td>
                      <td className="text__s text__center">
                        <span className="text__m">281</span>円<sup className="sup">※2</sup>
                      </td>
                    </tr>
                    <tr>
                      <td className="text__s text__bold text__center">おまけ</td>
                      <td className="text__s text__center">
                        <p className="text__bold mg__bottom__xs">
                          BASE Cookies
                          <br />
                          ココナッツ1袋
                          <sup className="sup text__normal">※3</sup>
                        </p>
                      </td>
                      <td className="text__m text__bold text__center">×</td>
                    </tr>
                    <tr>
                      <td className="text__s text__bold text__center">特典</td>
                      <td className="text__s text__center">
                        ランクに応じて<span className="text__bold">アウトレット</span>や
                        <span className="text__bold">限定イベントにご招待</span>、
                        <span className="text__bold">新商品30%割引</span>
                        <sup className="sup">※4</sup>など
                      </td>
                      <td className="text__m text__bold text__center">×</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="text__s mg__top__s text__gray__dark">
                ※1 BASE BREAD チョコレート1袋の場合。継続コース初回20%OFF、2回目以降10%OFF。 ※2 BASE
                BREAD
                チョコレート1袋の希望小売価格260円（税抜）に消費税率を乗じた場合（小数点以下切り捨て）。ただしコンビニやドラッグストアでの特売価格およびその他一部小売店舗でのセット販売は除きます。
                ※3 継続コース初回のみ。予告なく変更・終了する場合があります。 ※4
                発売開始から期間限定で適用されます。
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-m-5 col-offset-m-1 mg__top__m">
          <div className={clsx("bg__white", styles.subscriptionBox)}>
            <div className={styles.subscriptionMerit}>
              <dl className={styles.subscriptionMeritDetail}>
                <dt>
                  <span className="text__xxl text__bold text__yellow">2.</span>
                </dt>
                <dd>
                  <p className="text__l text__bold">便利</p>
                </dd>
              </dl>
              <p className="text__m mg__top__m">毎月のご注文が不要、お届け日時の指定/変更も可能</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-m-5 col-offset-m-1 mg__top__m">
          <div className={clsx("bg__white", styles.subscriptionBox)}>
            <div className={styles.subscriptionMerit}>
              <dl className={styles.subscriptionMeritDetail}>
                <dt>
                  <span className="text__xxl text__bold text__yellow">3.</span>
                </dt>
                <dd>
                  <p className="text__l text__bold">安心</p>
                </dd>
              </dl>
              <p className="text__m mg__top__m">
                いつでも、お届け日変更/ キャンセル可能 ※、解約条件なし
              </p>
              <p className="text__m mg__top__m text__gray__dark">
                ※1回目お届け以降。配送予定日の5日前まで手続き可能。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
