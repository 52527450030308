import { LetroSection } from "@/components/domains/product/ProductBase/FeatureSections/LetroSection";

export function InstagramSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1 text__center">
          <h2 className="text__bold text__xl text__xxl__pc text__center mg__bottom__l">
            Instagramで話題
          </h2>
        </div>
        <div className="col-12">
          <LetroSection letroId="3783" />
        </div>
      </div>
    </div>
  );
}
