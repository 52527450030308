import { useRef } from "react";

import clsx from "clsx";
import { Swiper as SwiperClass } from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Image } from "@/components/displays";
import { ProductsBySkuCalcPriceModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./SetPackListsSection.module.scss";

const discountInvitePrice = 2000;

const swiperOption = {
  modules: [Navigation, Pagination],
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  loop: false,
  slidesPerView: 1.05,
  spaceBetween: 20,
  centeredSlides: false,
};

interface SetPackListsSectionProps {
  productsBySkuCalcPrice: ProductsBySkuCalcPriceModel[];
}

export function SetPackListsSection({
  productsBySkuCalcPrice,
}: SetPackListsSectionProps): React.ReactNode {
  const swiperRef = useRef<SwiperClass | null>(null);

  return (
    <>
      <div className="clear__sp">
        <div className="row">
          {productsBySkuCalcPrice.map((product, index) => {
            return (
              <div key={index} className="col-12 col-m-4">
                <div
                  className={clsx(styles.threePoints, styles.startSet, {
                    mg__bottom__m: index === 0,
                  })}
                >
                  <p className="text__l text__bold mg__bottom__s text__center">{product.name}</p>
                  <div
                    className={clsx(
                      "pd__top__m",
                      "pd__bottom__m",
                      "text__center",
                      "mg__bottom__s",
                      styles.priceOutline
                    )}
                  >
                    <span
                      className={clsx(
                        "tag",
                        "red",
                        "radius",
                        "text__white",
                        "text__s",
                        styles.pricePercent
                      )}
                    >
                      友だち紹介限定価格
                    </span>
                    <br />
                    <del className="text__s">
                      通常価格{formatCurrency(product.defaultPrice)}（送料・税込）
                    </del>
                    <br />
                    <span className="text__red text__xl text__bold">
                      {formatCurrency(product.totalPrice - discountInvitePrice)}
                    </span>
                    <span className="text__s text__red">（送料・税込）</span>
                    <p className="text__s text__gray__dark text__left mg__top__s">
                      ※継続コース初回のみ ※2回目以降のご購入から
                      {formatCurrency(product.secondTotalPrice)}
                      （送料・税込）※1回目お届け以降、いつでも内容変更、解約が可能
                    </p>
                  </div>
                  <div className="pd__top__m pd__bottom__m mg__bottom__l">
                    <p className="text__s text__bold mg__bottom__m">BASE BREAD®︎</p>
                    {product.images &&
                      product.images.map((image, index) => (
                        <div key={index}>
                          {index !== 0 && (
                            <p className="text__s text__bold mg__top__m mg__bottom__m">
                              BASE Cookies®︎
                            </p>
                          )}
                          <Image
                            src={image.src}
                            alt={product.name || ""}
                            aspectRatio={image.aspectRatio}
                            containerClassName={clsx(styles.image, styles.fullImages)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="clear__pc">
        <Swiper
          {...swiperOption}
          className={styles.contentsSlider}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {productsBySkuCalcPrice.map((product, index) => {
            return (
              <SwiperSlide key={index} className={styles.swiperWrapper}>
                <div
                  className={clsx(styles.threePoints, styles.startSet, {
                    mg__bottom__m: index === 0,
                  })}
                >
                  <p className="text__l text__bold mg__bottom__s text__center">{product.name}</p>
                  <div
                    className={clsx(
                      "pd__top__m",
                      "pd__bottom__m",
                      "text__center",
                      "mg__bottom__s",
                      styles.priceOutline
                    )}
                  >
                    <span
                      className={clsx(
                        "tag",
                        "red",
                        "radius",
                        "text__white",
                        "text__s",
                        styles.pricePercent
                      )}
                    >
                      友だち紹介限定価格
                    </span>
                    <br />
                    <del className="text__s">
                      通常価格{formatCurrency(product.defaultPrice)}（送料・税込）
                    </del>
                    <br />
                    <span className="text__red text__xl text__bold">
                      {formatCurrency(product.totalPrice - discountInvitePrice)}
                    </span>
                    <span className="text__s text__red">（送料・税込）</span>
                    <p className="text__s text__gray__dark text__left mg__top__s">
                      ※継続コース初回のみ ※2回目以降のご購入から
                      {formatCurrency(product.secondTotalPrice)}
                      （送料・税込）※1回目お届け以降、いつでも内容変更、解約が可能
                    </p>
                  </div>
                  <div className="pd__top__m pd__bottom__m mg__bottom__l">
                    <p className="text__s text__bold mg__bottom__m">BASE BREAD®︎</p>
                    {product.images &&
                      product.images.map((image, index) => (
                        <Image
                          key={index}
                          src={image.src}
                          alt={product.name || ""}
                          aspectRatio={image.aspectRatio}
                          containerClassName={clsx(styles.image, styles.fullImages)}
                        />
                      ))}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </>
  );
}
