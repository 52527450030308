import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./RecommendationsSection.module.scss";

export function RecommendationsSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-8 col-offset-m-2">
          <div className="text__center">
            <h2 className="text__xl text__bold">こんなときにおすすめ</h2>
          </div>
          <Image
            src="https://asset.basefood.co.jp/images/ph/illust_frame_workers.png"
            alt=""
            aspectRatio="160 / 109"
            containerClassName={clsx(styles.image, styles.fullImages)}
          />
          <div className={clsx("text__center", styles.timingFlex)}>
            <p className={clsx("text__m", "text__bold", styles.timing)}>小腹が空いたとき</p>
            <p className={clsx("text__m", "text__bold", styles.timing)}>罪悪感のないおやつ</p>
            <p className={clsx("text__m", "text__bold", styles.timing)}>ばたばたしがちな朝食</p>
            <p className={clsx("text__m", "text__bold", styles.timing)}>
              デスクですませたい平日のランチ
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
