"use client";

import { OnePageCheckoutForm, ScrollButton } from "@/components/domains/lp";
import { Faq } from "@/components/domains/lp/Faq";
import { SafetyBanner } from "@/components/domains/lp/SafetyBanner";
import { ProductModel, ProductsBySkuCalcPriceModel } from "@/models/product/type";

import { BodyChangeSection } from "./BodyChangeSection";
import { BreakfastFitSection } from "./BreakfastFitSection";
import { FeelingChangeBodySection } from "./FeelingChangeBodySection";
import { FriendsOnlySpecialSet } from "./FriendsOnlySpecialSet";
import { HealthEssentialsSection } from "./HealthEssentialsSection";
import { IdealNutritionBalanceSection } from "./IdealNutritionBalanceSection";
import { InstagramSection } from "./InstagramSection";
import { LineupSection } from "./LineupSection";
import { RecommendationsSection } from "./RecommendationsSection";
import { StartSetSection } from "./StartSetSection";

interface InviteProps {
  products: ProductModel[];

  productsBySkuCalcPrice: ProductsBySkuCalcPriceModel[];
}

export function Invite({ products, productsBySkuCalcPrice }: InviteProps): React.ReactNode {
  return (
    <main>
      <section className="breadcrumb__pd pd__top__off__pc">
        <FriendsOnlySpecialSet />
      </section>

      <section className="bg__yellow">
        <SafetyBanner lpType="invite" />
        <StartSetSection productsBySkuCalcPrice={productsBySkuCalcPrice} />
      </section>

      <div className="bg__yellow pd__top__l clear__pc" />

      <section className="pd__top__xl pd__bottom__xl bg__gray">
        <HealthEssentialsSection />
      </section>

      <section className="bg__yellow pd__top__xl pd__bottom__xl">
        <LineupSection />
      </section>

      <section className="pd__top__xl pd__bottom__xl bg__gray">
        <InstagramSection />
      </section>

      <section className="pd__top__off mg__top__xl pd__bottom__off mg__bottom__xl">
        <BreakfastFitSection />
      </section>

      <section className="pd__top__xl pd__bottom__xl bg__yellow">
        <IdealNutritionBalanceSection />
      </section>

      <section className="bg__gray pd__top__xl pd__bottom__xl">
        <RecommendationsSection />
      </section>

      <section className="bg__white">
        <Faq />
      </section>

      <section className="bg__yellow pd__top__xl">
        <BodyChangeSection />
      </section>

      <section className="bg__yellow pd__top__xl pd__bottom__xl">
        <FeelingChangeBodySection />
      </section>

      <section className="bg__yellow pd__top__xl pd__bottom__xl">
        <StartSetSection
          productsBySkuCalcPrice={productsBySkuCalcPrice}
          showIntroductionImage={true}
        />
      </section>

      <div className="bg__yellow pd__top__l clear__pc" />

      <section className="bg__white pd__top__xl__pc pd__bottom__xl__pc">
        <div className="container bg__white">
          <div className="row pd__top__l pd__bottom__l">
            <div className="col-12 col-m-8 col-offset-m-2">
              <OnePageCheckoutForm
                products={products}
                variant="INVITE_SUBSCRIPTION_COUPON_DISCOUNT_REQUIRED"
                pageFrom="友だち紹介キャンペーン｜BASE FOOD"
                isInvited={true}
              />
            </div>
          </div>
        </div>
      </section>

      <ScrollButton pattern={"simple"} />
    </main>
  );
}
